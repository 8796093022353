const ERROR_TAG_NAME = "small";
const ERROR_CLASS_NAME = "form__error";

const getClosestSibling = (referenceElement: ParentNode | null, selector: string, maxDepth: number = -1): Element | null => {
  if (!referenceElement || maxDepth < 1) {
    return null;
  }

  const closestElement = referenceElement.querySelector(selector);
  return closestElement ?? getClosestSibling(referenceElement.parentNode, selector, maxDepth > 0 ? maxDepth - 1 : -1);
};

const clearErrors = (inputElement: Element, errorElement: Element | null) => {
  if (errorElement) {
    errorElement.remove();
    inputElement.classList.remove(ERROR_CLASS_NAME);
  }
};

export const validateField = (inputElement: HTMLElement) => {
  if (
    !(inputElement instanceof HTMLInputElement)
    && !(inputElement instanceof HTMLSelectElement)
    && !(inputElement instanceof HTMLTextAreaElement)
  ) {
    return;
  }

  const existingError = getClosestSibling(inputElement.parentNode, `${ERROR_TAG_NAME}.${ERROR_CLASS_NAME}`, 3);

  if (inputElement.validity.valid) {
    clearErrors(inputElement, existingError);
    return;
  }

  if (existingError) {
    return;
  }

  const closestElement = inputElement.closest("div");

  inputElement.classList.add(ERROR_CLASS_NAME);

  const errorElement = document.createElement(ERROR_TAG_NAME);
  errorElement.classList.add(ERROR_CLASS_NAME);
  errorElement.innerText = inputElement.dataset["msg"] ?? "Dieses Feld ist ein Pflichtfeld.";

  const errorPlacement = inputElement.dataset["errorPlacement"] ?? "";

  switch (errorPlacement) {
    case "before-element":
      inputElement.before(errorElement)
      break;
    case "after-element":
      inputElement.after(errorElement);
      break;
    case "before":
      closestElement?.before(errorElement)
      break;
    case "after":
    default:
      closestElement?.after(errorElement)
      break;
  }
};
