import {validateField} from "./validate-field";

export const installFormValidation = (formElement: HTMLFormElement) => {
  for (const element of formElement.elements) {
    element.addEventListener("invalid", () => {
      validateField(element as HTMLElement);
    });

    element.addEventListener("input", () => {
      validateField(element as HTMLElement);
    });
  }
};
