import $ from 'jquery';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/form-reset-mixin';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/labels';
import 'jquery-ui/ui/position';
import 'jquery-ui/ui/unique-id';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/widgets/selectmenu';
import { registerSelectMenuRefreshHandler } from './select-menu-refresh';
import {installFormValidation} from "../validate-form/install-form-validation";
import {validateField} from "../validate-form/validate-field";

export const initializeSelectMenu = () => {
  let getAdditionalClasses = (element?: HTMLElement) => {
    if (!element) {
      return ''
    }

    return element.dataset["additionalClasses"] || ''
  }

  /*
 * Initializes the select fields with jQueryUI SelectMenu
 */
  $('select.selectmenu').each((_, element) => {
    $(element).on('selectmenuchange', function (event, ui) {
      if ($(element).attr('onchange')) {
        /**
         * TODO Warning: Executing JavaScript from a string is an enormous security risk. It is
         *  far too easy for a bad actor to run arbitrary code when you use eval().
         *  See Never use eval()!, below.
         *  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
         *
         *  The existing selectmenus should get the data-onchange attribute with predefined actions, e.g.
         *  "form-submit". Based on the select, the callbcks are to be carried out over here.
         */
        eval($(element).attr('onchange') || "")
      }

      if (!(this instanceof HTMLSelectElement)) {
        return
      }

      switch (this.dataset['onchange']) {
        case 'change':
          this.dispatchEvent(new Event('change'))
          break
        default:
          break
      }
    }).find('option').each(function () {
      if ($(element).data('selected')) {
        $(element).attr('selected', 'selected')
      }
    })

    $(element).selectmenu({
      change: function (event: Event, ui: any) {
        validateField(element);
      },
      classes: {
        'ui-selectmenu-button': getAdditionalClasses(this),
        'ui-selectmenu-menu': getAdditionalClasses(this),
      }
    })
  })

  const forms = document.querySelectorAll<HTMLFormElement>('form:has(.selectmenu)')
  for (const formElement of forms) {
    installFormValidation(formElement);
  }

  registerSelectMenuRefreshHandler((element) => {
    $(element).selectmenu('refresh');
  })
}
